<template>
  <div class="main">
    <breadcrumb>
      <breadcrumb-item>{{$t('OrderManagement')}}</breadcrumb-item>
      <breadcrumb-item>{{$t('Cancel order')}}</breadcrumb-item>
    </breadcrumb>
    <div class="filter cl">
      <div class="item cl">
        <div class="label">{{$t('Order number')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.orderNo"/>
        </div>
      </div>
<!--      <div class="item cl">-->
<!--        <div class="label">{{$t('Order Contact')}}：</div>-->
<!--        <div class="fl">-->
<!--          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"-->
<!--                 v-model.trim="filterData.contactName"/>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="item cl">-->
<!--        <div class="label">{{$t('Group status')}}：</div>-->
<!--        <div class="fl">-->
<!--          <el-select class="select" clearable v-model="status" size="small" :placeholder="$t('All')">-->
<!--            <el-option v-for="item in statusOptions"-->
<!--                       :key="item.value"-->
<!--                       :label="item.label"-->
<!--                       :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
<!--      </div>-->
      <div class="item cl">
        <div class="label">{{$t('ProductNumber')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.productNo"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Contact Number')}}：&#x3000;</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.contactPhone"/>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('Order status')}}：</div>
        <div class="fl">
          <el-select class="select" clearable size="small" :placeholder="$t('All')" v-model="filterData.orderStatus">
            <el-option v-for="(item,key) of $t('OrderStatus')"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item cl">
        <div class="label">{{$t('ProductName')}}：</div>
        <div class="fl">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                 v-model.trim="filterData.productTitle"/>
        </div>
      </div>
      <div class="fl">
        <button class="button button-primary small m-r" type="button" @click="getOrderList(1)">{{$t('search')}}</button>
        <button class="button small" type="button" @click="clearFilterData">{{$t('Clear')}}</button>
      </div>
    </div>
    <tabs v-model="activeName">
      <tab-pane :label="$t('All')" name="all"></tab-pane>
<!--      <tab-pane label="客戶退款審核中" name="status1"></tab-pane>-->
<!--      <tab-pane label="商戶退款審核中" name="status2"></tab-pane>-->
      <tab-pane :label="$t('Customer Cancellation')" name="USER_CHECK_REFUND"></tab-pane>
      <tab-pane label="已確認取消" name="USER_CONFIRM_REFUND"></tab-pane>
    </tabs>
    <div class="table border">
      <table>
        <thead>
        <tr>
          <th>{{$t('Operating')}}</th>
          <th>{{$t('Order number')}}</th>
          <th>{{$t('ProductNumber')}}</th>
          <th>{{$t('ProductName')}}</th>
          <th>{{$t('Trading time')}}</th>
          <th>{{$t('Order people number')}}</th><!--訂購人數-->
<!--          <th>{{$t('Balance')}}</th>&lt;!&ndash;餘額&ndash;&gt;-->
<!--          <th>{{$t('Group status')}}</th>-->
          <th>{{$t('Order status')}}</th>
<!--          <th>{{$t('Notification Status')}}</th> -->
          <!--通知狀態-->

        </tr>
        </thead>
        <tbody>
        <tr v-for="item of list" :key="item.orderId">
          <td class="nowrap">
            <!--<a class="btn-item" @click="openDetail(i)">
              <img src="../../../assets/images/icon/pen.png"/>
            </a>
            <a class="btn-item">
              <img src="../../../assets/images/icon/unlock.png"/>
            </a>-->
            <router-link class="btn-item" target="_blank" :to="{name:'AdminOrderDetail',query:{id:item.orderId}}">
              <img src="../../../assets/images/icon/review.png"/>
            </router-link>
          </td>
          <td>{{item.orderNo}}</td>
          <td>{{item.productNo}}</td>
          <td>{{item.productTitle}}</td>
          <td>{{item.orderTime|formatDate('yyyy.MM.dd hh:mm')}}</td>
          <td>{{item.orderPeopleNumber||0}}{{$t('People')}}</td>
<!--          <td class="nowrap">{{item.showBalance}}</td>-->
<!--          <td>-->
<!--            &lt;!&ndash;<i class="status-circle"></i><span class="vam">已成团</span>&ndash;&gt;-->
<!--            {{item.groupStatus}}-->
<!--          </td>-->
          <td>{{$t('OrderStatus')[item.orderStatus]}}</td>
<!--          <td>{{item.isGroupNotice==='Y'?$t('GroupNoticeHasBeenSent'):''}}</td>-->
          <!--成團通知已發送-->

        </tr>
        </tbody>
      </table>
      <empty v-if="isEmpty" :title="$t('NoData')"/>
      <el-pagination class="pages txt-r"
                     layout="prev, pager, next"
                     :page-count="totalPages"
                     @current-change="getOrderList"
                     hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import Breadcrumb from '../../../components/admin/Breadcrumb';
  import BreadcrumbItem from '../../../components/BreadcrumbItem';
  import Tabs from '../../../components/Tabs/Tabs';
  import TabPane from '../../../components/Tabs/TabPane';
  import Empty from '../../../components/Empty';
  import {getCancelOrderList} from '../../../api/admin-order';

  export default {
    name: 'CancelOrderList',
    data() {
      return {
        activeName: 'all',
        statusOptions: [
          {value: '1', label: '上線'},
          {value: '2', label: '暂停'}
        ],
        status: '',
        filterData: {
          isMy: 'N',
          orderNo: '',
          contactName: '',
          productNo: '',
          contactPhone: '',
          orderStatus: '',
          productTitle: '',
          activeName: ''
        },
        list: [],
        totalPages: 1,
        isEmpty: false
      };
    },
    components: {Empty, Breadcrumb, BreadcrumbItem, Tabs, TabPane},
    created() {
      this.getOrderList();
    },
    methods: {
      openDetail(id) {
        const routeUrl = this.$router.resolve({name: 'AdminCancelOrderDetail', params: {id}});
        window.open(routeUrl.href, '_blank');
      },
      // 获取订单列表
      getOrderList(page = 1) {
        if (this.activeName !== 'all') {
          this.filterData.activeName = this.activeName;
        }
        const params = {page, perPage: 10};
        getCancelOrderList(params, this.filterData).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
          this.isEmpty = params.page === 1 && !this.list.length;
        });
      },
      // 清除过滤参数
      clearFilterData() {
        let filterData = this.$options.data().filterData;
        this.filterData = filterData;
        this.getOrderList();
      }
    },
    watch: {
      $route: function (value) {
        this.clearFilterData();
      },
      activeName: function (value) {
        this.getOrderList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    /deep/ .tab-nav{
      margin-top:6px;
      .item{
        width:auto;margin-right:32px;
        &:after{right:0;left:0;width:auto;}
        a{min-width:auto;}
      }
    }
    .status-circle{
      display:inline-block;width:6px;height:6px;margin-right:10px;vertical-align:middle;background-color:#00b680;border-radius:50%;
      &.orange{background-color:#ff9b00;}
      &.gray{background-color:#999;}
    }
  }
  .filter{
    margin-top:30px;
    .item{
      float:left;width:33.3333%;margin-bottom:24px;vertical-align:top;
      .label{float:left;line-height:32px;}
    }
    .ipt{
      width:160px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    /deep/ .select{width:160px;vertical-align:top;}
  }
</style>
